import { useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import clsx from "clsx";
import SlideoutMenu from "../lib/components/slideout/SlideoutMenu";
import SideNavContent from "./SideNavContent";
import CartIcon from "./CartIcon";
import "./SideNav.scss";

type SideNavProps = {
  inverted: boolean;
};

export default function SideNav({ inverted }: SideNavProps) {
  const [open, setOpen] = useState<boolean>(false);
  const openMenu = () => setOpen(true);
  const closeMenu = () => setOpen(false);
  const logoName = inverted ? "logo-long-thick-inverted" : "logo-long-thick";

  return (
    <>
      <SlideoutMenu open={open} onClose={closeMenu}>
        <SideNavContent />
      </SlideoutMenu>
      <div id="side-nav" className={clsx({ inverted })}>
        <div onClick={openMenu}>
          <Icon name="content" size="large" className="menu-button" />
        </div>
        <div>
          <Link to="/">
            <img
              className="logo"
              key="logo-long"
              srcSet={`/images/logo/${logoName}-64h.png 2x, /images/logo/${logoName}-32h.png 1x`}
              alt="photomaps.com logo"
            />
          </Link>
        </div>
        <div>
          <CartIcon hideIfEmpty />
        </div>
      </div>
    </>
  );
}
