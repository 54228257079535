import {
  InitialFileProperties,
  serializeInitialFileProperties,
} from "@mapmaker/core";
import { MapmakerAppConfig, MapmakerPrintServiceId } from "@mapmaker/frontend";
import { NavigateFunction } from "react-router-dom";
import { ProjectOutputType } from "../../MapmakerApi";

function createProjectSharingLink(fileId: string): string {
  return `${window.location.origin}/p/${fileId}?s=1`;
}

let navigate: NavigateFunction;
export function setNavigate(n: NavigateFunction) {
  navigate = n;
}

function gotoSignUp(
  redirectTo: string = window.location.pathname + window.location.search
) {
  navigate(`/account/register?redirectTo=${encodeURIComponent(redirectTo)}`);
}

function gotoSignIn(
  redirectTo: string = window.location.pathname + window.location.search
) {
  navigate(`/account/login?redirectTo=${encodeURIComponent(redirectTo)}`);
}

function gotoFindMap() {
  navigate(`/create`);
}

const stripBusinessId = (designId: string) => designId.replace(/^pm./, "");

function gotoNewFile(
  designId: string,
  initialFileProperties?: InitialFileProperties
) {
  const search = initialFileProperties
    ? `?ies=${serializeInitialFileProperties(initialFileProperties)}`
    : "";
  navigate(`/p/new/${stripBusinessId(designId)}${search}`);
}

function gotoEditFile(fileId: string, ...path: string[]) {
  if (path.length === 0) {
    navigate(`/p/${fileId}`);
  } else {
    navigate(`/p/${fileId}/${path.filter((x) => !!x).join("/")}`);
  }
}

// Called after the first save of a new map when the file ID is available.
function onMapmakerFileCreated(fileId: string) {
  window.history.replaceState(null, "", `/p/${fileId}`);
}

function gotoSelectPrintService(fileId: string) {
  navigate(`/p/${fileId}/output`);
}

function gotoCreatePrints(service: MapmakerPrintServiceId, fileId: string) {
  switch (service) {
    case MapmakerPrintServiceId.DOWNLOAD:
      navigate(`/p/${fileId}/output/download`);
      break;
    case MapmakerPrintServiceId.STICKERS:
      navigate(`/p/${fileId}/output/stickers`);
      break;
  }
}

function gotoPrintSizingHelp() {
  navigate(`/diy-photos`);
}

function gotoContact(category: "bug" | undefined) {
  navigate(`/contact`);
}

function gotoChangeEmail() {
  navigate(`/contact/technical/change-email`);
}

function gotoProduct(handle: string) {
  navigate(`/product/${handle}`);
}

function gotoCart() {
  navigate(`/cart`);
}

function gotoStickerTokenRedeem(code: string = "") {
  navigate(`/redeem?code=${code}`);
}
function gotoStickerTokenHelp() {
  navigate(`help/sticker-tokens`);
}
function gotoStickerTokenBalance() {
  navigate(`account/sticker-tokens`);
}

function gotoBlogPosts() {
  navigate(`/blog`);
}

function gotoBlogPost(slug: string) {
  navigate(`/blog/${slug}`);
}

const PMMapmakerAppConfig: Omit<MapmakerAppConfig, "stickerAdapter"> = {
  businessId: "pm",
  businessName: "photomaps.com",
  pageTitleFormat: "{TITLE} | photomaps.com",
  printServiceIds: [
    MapmakerPrintServiceId.STICKERS,
    MapmakerPrintServiceId.DOWNLOAD,
  ],
  primaryPrintServiceId: MapmakerPrintServiceId.STICKERS,
  stickersConfig: {
    shippingCost: "$1.75",
    freeShippingThreshold: 5,
  },
  downloadConfig: {
    bufferArea: false,
  },
  defaultProjectOutputType: ProjectOutputType.VinylWallMap,
  sanity: {
    dataset: "production",
    projectId: "6bdy1j1i",
  },
  onMapmakerFileCreated,
  // URLs
  createProjectSharingLink,
  // Navigation
  gotoCreatePrints,
  gotoEditFile,
  gotoFindMap,
  gotoNewFile,
  gotoSelectPrintService,
  gotoSignIn,
  gotoSignUp,
  gotoPrintSizingHelp,
  gotoContact,
  gotoChangeEmail,
  gotoProduct,
  gotoCart,
  gotoStickerTokenRedeem,
  gotoStickerTokenHelp,
  gotoStickerTokenBalance,
  gotoBlogPost,
  gotoBlogPosts,
};
export default PMMapmakerAppConfig;
