import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const CartPage = lazy(() => import("./cart/CartPage"));
const BrowseProductsPage = lazy(() => import("./browse/BrowseProductsPage"));

export default function ShopRouter() {
  return (
    <Routes>
      <Route path="cart" element={<CartPage />} />
      <Route element={<BrowseProductsPage />}>
        <Route path="/:handle" />
        <Route path="/" />
      </Route>
      <Route path="/*" element={<Navigate to={"/"} />} />
    </Routes>
  );
}
