import clsx from "clsx";
import { HTMLProps, ReactNode, useEffect } from "react";
import useTitle from "react-use/lib/useTitle";
import { HeaderMode, useGlobalLayout } from "./useGlobalLayout";
import "./Page.scss";

export interface IPageProps extends HTMLProps<HTMLDivElement> {
  title: string;
  children: ReactNode;
  area?: "standard" | "full";
  headerMode?: HeaderMode;
}

export default function Page({
  children,
  title,
  area = "standard",
  headerMode = "normal",
  ...props
}: IPageProps) {
  const { setHeaderMode } = useGlobalLayout();
  useTitle(`${title} | photomaps.com`);
  useEffect(() => {
    setHeaderMode(headerMode);
    return () => {
      setHeaderMode("normal");
    };
  }, [headerMode, setHeaderMode]);

  return (
    <>
      <div
        {...props}
        className={clsx("pm-page", `area-${area}`, props.className)}
      >
        {children}
      </div>
    </>
  );
}
