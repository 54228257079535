import { Suspense, lazy, useEffect } from "react";
import * as Sentry from "@sentry/react";
import PhotomapsDotComConfig from "@photomaps/config";
import {
  BrowserRouter,
  Routes,
  Navigate,
  Route,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import clsx from "clsx";
import TagManager from "react-gtm-module";
import useWindowSize from "react-use/lib/useWindowSize";
import {
  AuthenticatedUser,
  AuthState,
  AWSAuthContextProvider,
  Credentials,
  useAuthContext,
} from "@tbl/aws-auth";
import {
  MapmakerContextProvider,
  MapmakerPrivacyPolicyPage,
  MapmakerRefundPolicyPage,
  MapmakerTermsOfServicePage,
} from "@mapmaker/frontend";
import Nav from "./layout/Nav";
import Footer from "./layout/Footer";
import PMMapmakerAppConfig, {
  setNavigate,
} from "./pages/mapmaker/PMMapmakerAppConfig";
import "./PhotomapsDotComApp.scss";
import "./pages/mapmaker/PMMapmakerTheme.scss";

// Currently needed for Semantic UI in Map Maker.
import "./semantic/semantic.min.css";

// Routers and pages that we load immediately to prevent double-loading calls later
import ShopRouter from "./pages/shop/ShopRouter";
import AccountRouter from "./pages/account/AccountRouter";
import LoadingPage from "./lib/components/messages/LoadingPage";
import Page from "./layout/Page";
import { GlobalLayoutProvider } from "./layout/useGlobalLayout";

// Bug tracking
Sentry.init({
  dsn: "https://798fadd6c1034eb4998c9939fdf4acd8@o450330.ingest.sentry.io/5615450",
  release: "photomaps-dot-com@2023-03-06.0",
  environment: PhotomapsDotComConfig.stage,
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
  ],
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: process.env.NODE_ENV !== "development",
    }),
  ],
});

// Tag Manager
TagManager.initialize({
  gtmId: "GTM-WQKDRTK",
});

function AppWithAuthContext() {
  return (
    <AWSAuthContextProvider
      config={{
        identityPoolId: PhotomapsDotComConfig.aws.cognito.identityPoolId,
        region: PhotomapsDotComConfig.aws.region,
        userPoolId: PhotomapsDotComConfig.aws.cognito.userPoolId,
        userPoolWebClientId: PhotomapsDotComConfig.aws.cognito.appClientId,
      }}
    >
      <BrowserRouter>
        <AppWithContext />
      </BrowserRouter>
    </AWSAuthContextProvider>
  );
}

async function logCredentialError(
  currentCredentials: () => Promise<Credentials>,
  user: AuthenticatedUser
) {
  console.log(
    `Unexpected Auth error.
    ${await currentCredentials()}
    ${user}`
  );
}

function AppWithContext() {
  const { state, user, identityId, currentCredentials } = useAuthContext();

  useEffect(() => {
    Sentry.setUser({
      id: identityId,
      email: user?.email,
    });
  }, [identityId, user]);

  if (state === AuthState.Initializing) {
    return null;
  } else if (state === AuthState.Error) {
    // If we bail here people won't have access to anything on the site, so let's just log it
    // for now.
    logCredentialError(currentCredentials, user);
  }
  return (
    <MapmakerContextProvider
      appConfig={{ ...PMMapmakerAppConfig }}
      identityId={identityId}
      currentCredentials={currentCredentials}
    >
      <App />
    </MapmakerContextProvider>
  );
}

const visitedKeys: { [key: string]: boolean } = {};

/**
 * Pages linked directly but not loaded until visited.
 */
const HomePage = lazy(() => import("./pages/home/HomePage"));
const ProductPage = lazy(() => import("./pages/shop/product/ProductPage"));
const BlogRouter = lazy(() => import("./pages/blog/BlogRouter"));
const MapMakerRouter = lazy(() => import("./pages/mapmaker/MapMakerRouter"));
const RedeemPage = lazy(() => import("./pages/account/RedeemPage"));
const ContactPage = lazy(() => import("./pages/contact/ContactPage"));
const HelpPage = lazy(() => import("./pages/help/HelpPage"));
const DebugPage = lazy(() => import("./pages/help/DebugPage"));
const CartPage = lazy(() => import("./pages/shop/cart/CartPage"));
const AboutUsPage = lazy(() => import("./pages/other/AboutUsPage"));
const TikTokPromotionPage = lazy(
  () => import("./pages/promotions/TikTokPromotionPage")
);
const InstagramPromotionPage = lazy(
  () => import("./pages/promotions/InstagramPromotionPage")
);

function App() {
  // This is sketchy but our mapmaker app config needs access to the navigate object from React
  // Router so we set it here.
  const navigate = useNavigate();
  setNavigate(navigate);

  const location = useLocation();
  const isMapmakerLayout = !!useMatch("p/*");

  // This is for dealing with the address bar height on Android. If the user navigates from a page
  // where the address bar is not showing (after they scroll down) to a page where the content does
  // not fill the page vertically, the body height will only be the size of the window without the
  // address bar leaving a large gap at the bottom of the screen.
  const { height: windowHeight } = useWindowSize();

  // This will scroll the page to the top for any new states pushed to the history. Going back to a
  // previous state will *not* restore the scroll which is desired on forward/back actions.
  useEffect(() => {
    if (location.key) {
      if (!visitedKeys[location.key]) {
        window.scrollTo(0, 0);
      }
      visitedKeys[location.key] = true;
    }
  }, [location.key, location.search]);

  return (
    <GlobalLayoutProvider>
      <div
        className={clsx("pm-layout", { "mapmaker-layout": isMapmakerLayout })}
        style={{ minHeight: windowHeight }}
      >
        <Nav />
        <main>
          <Suspense fallback={<LoadingPage />}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              {/* Redirects */}
              {/* We used /faq until Feb 2022 for our only help content */}
              <Route path="faq" element={<Navigate to="help" replace />} />
              {/* Used this link quite a bit, probably safe to remove by 2023. */}
              <Route
                path="diy-photos"
                element={<Navigate to="/help/print-at-home" replace />}
              />
              {/* Convenience so TBL.com and PM.com match. */}
              <Route
                path="mapmaker"
                element={<Navigate to="/create" replace />}
              />
              <Route
                path="sandbox"
                element={<Navigate to="/p/sandbox" replace />}
              />

              {/* Main paths */}
              <Route path="account/*" element={<AccountRouter />} />
              <Route path="shop/*" element={<ShopRouter />} />
              <Route path="cart" element={<CartPage />} />
              <Route path="product/:handle" element={<ProductPage />} />
              <Route path="create" element={<MapMakerRouter />} />
              <Route path="p/*" element={<MapMakerRouter />} />
              <Route path="help">
                <Route path="" element={<HelpPage />} />
                <Route path=":slug" element={<HelpPage />} />
              </Route>
              <Route path="blog/*" element={<BlogRouter />} />
              <Route path="contact" element={<ContactPage />}>
                <Route path="" />
                <Route path=":slug" />
                <Route path="*" />
              </Route>
              <Route path="about" element={<AboutUsPage />} />
              <Route path="debug" element={<DebugPage />} />

              {/* Special landing pages */}
              <Route path="redeem" element={<RedeemPage />} />
              <Route path="tiktok" element={<TikTokPromotionPage />} />
              <Route
                path="insta"
                element={<Navigate to="/instagram" replace />}
              />
              <Route path="ig" element={<Navigate to="/instagram" replace />} />
              <Route path="instagram" element={<InstagramPromotionPage />} />

              {/* Special Shopify policy pages */}
              <Route
                path="refunds"
                element={
                  <Page title="Refund Policy">
                    <MapmakerRefundPolicyPage />
                  </Page>
                }
              />
              <Route
                path="privacy"
                element={
                  <Page title="Privacy Policy">
                    <MapmakerPrivacyPolicyPage />
                  </Page>
                }
              />
              <Route
                path="terms"
                element={
                  <Page title="Terms of Service">
                    <MapmakerTermsOfServicePage />
                  </Page>
                }
              />
              {/** We can't change our shopify default website, so we redirect to
               * /shopify-redirect/... for everything except the cart page. The product page is the
               * most important which we handle here. */}
              <Route
                path="shopify-redirect/shop/products/:handle"
                element={<ShopifyProductRouter />}
              />
              {/** Everything else should go to the homepage. */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </div>
    </GlobalLayoutProvider>
  );
}

function ShopifyProductRouter() {
  const { handle = "" } = useParams();
  return <Navigate to={`/product/${handle}`} replace={true} />;
}

export default AppWithAuthContext;
