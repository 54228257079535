import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { useAuthContext } from "@tbl/aws-auth";

const AuthPage = lazy(() => import("./AuthPage"));
const AccountPage = lazy(() => import("./AccountPage"));

export default function AccountRouter() {
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route element={<AuthPage />}>
          <Route path=":authBoxStateUrl" />
          <Route path="/" />
        </Route>
      </Routes>
    );
  } else {
    return <AccountPage />;
  }
}
