import { useMapmakerContext } from "@mapmaker/frontend";
import { useAuthContext } from "@tbl/aws-auth";
import clsx from "clsx";
import { Link, NavLink } from "react-router-dom";
import classes from "./SideNavContent.module.scss";

export default function SideNavContent() {
  const { isAuthenticated } = useAuthContext();
  const { account } = useMapmakerContext();

  return (
    <div className={classes.sideNavContent}>
      <Link to="/">
        <img
          className={classes.logo}
          key="logo-long"
          srcSet="/images/logo/logo-long-64h.png 2x, /images/logo/logo-long-32h.png 1x"
          alt="photomaps.com logo"
        />
      </Link>
      <div className={clsx(classes.group, classes.account)}>
        {isAuthenticated ? (
          <AuthenticatedAccountInfo />
        ) : (
          <UnauthenticatedAccountInfo />
        )}
      </div>
      <div className={classes.group}>
        <ul>
          <h3>Photo Maps</h3>
          <NavLink to="/">
            <li>Home</li>
          </NavLink>
          <NavLink to="shop">
            <li>Shop</li>
          </NavLink>
          <NavLink to="/create">
            <li>
              Your Projects
              {(account?.mapmakerFileCount ?? 0) > 0 && (
                <div className={classes.badge}>
                  {account?.mapmakerFileCount}
                </div>
              )}
            </li>
          </NavLink>
          <NavLink to="/about">
            <li>About Us</li>
          </NavLink>
        </ul>
      </div>
      <div className={classes.group}>
        <ul>
          <h3>Help</h3>
          <NavLink to="/help">
            <li>General Help</li>
          </NavLink>
          <NavLink to="/contact">
            <li>Contact Us</li>
          </NavLink>
          <NavLink to="/privacy">
            <li>Privacy</li>
          </NavLink>
        </ul>
      </div>
    </div>
  );
}

function UnauthenticatedAccountInfo() {
  return (
    <div className={classes.buttons}>
      <Link to="/account/register">Register</Link>
      <Link to="/account/login">Log In</Link>
    </div>
  );
}

function AuthenticatedAccountInfo() {
  const { signOut } = useAuthContext();
  return (
    <div className={classes.buttons}>
      <Link to="/account">My Account</Link>
      <span className={classes.link} onClick={signOut}>
        Log Out
      </span>
    </div>
  );
}
