import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
// Polyfill for flatMap on older Safari versions
import App from "./PhotomapsDotComApp";
import "./index.css";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
