import useWindowSize from "react-use/lib/useWindowSize";

const MOBILE_CUTOFF = 768;

export default function useDevice() {
  const { width } = useWindowSize();
  const isMobile = width < MOBILE_CUTOFF;
  return {
    isMobile,
    isComputer: !isMobile,
  };
}
