import { useMapmakerContext } from "@mapmaker/frontend";
import clsx from "clsx";
import { Link, NavLink } from "react-router-dom";
import AccountIcon from "./AccountIcon";
import CartIcon from "./CartIcon";
import classes from "./TopNav.module.scss";

type TopNavProps = {
  inverted: boolean;
};

export default function TopNav({ inverted }: TopNavProps) {
  const { account } = useMapmakerContext();
  const logoName = inverted ? "logo-long-inverted" : "logo-long";

  return (
    <>
      <div
        key="header"
        className={clsx({
          [classes.topNav]: true,
          [classes.inverted]: inverted,
        })}
      >
        <div className={classes.logo}>
          <Link to="/">
            <img
              key="logo-long"
              srcSet={`/images/logo/${logoName}-96h.png 2x, /images/logo/${logoName}-48h.png 1x`}
              alt="photomaps.com logo"
            />
          </Link>
        </div>
        <div>
          <ul className={classes.menu}>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/shop">Shop</NavLink>
            </li>
            <li>
              {(account?.mapmakerFileCount ?? 0) > 0 ? (
                <NavLink to="/create">
                  Your Projects
                  <span className={classes.projectCountLabel}>
                    {account?.mapmakerFileCount}
                  </span>
                </NavLink>
              ) : (
                <NavLink to="/create">Create a Project</NavLink>
              )}
            </li>
            <li>
              <NavLink to="/help">Help</NavLink>
            </li>
          </ul>
        </div>
        <div className={classes.secondary}>
          <CartIcon />
          <AccountIcon />
        </div>
      </div>
    </>
  );
}
