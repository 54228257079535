import useDevice from "../lib/hooks/useDevice";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import { useGlobalLayout } from "./useGlobalLayout";

export default function Nav() {
  const { isMobile } = useDevice();
  const { headerMode } = useGlobalLayout();
  const inverted = headerMode === "inverted";
  return (
    <nav>
      {isMobile ? (
        <SideNav inverted={inverted} />
      ) : (
        <TopNav inverted={inverted} />
      )}
    </nav>
  );
}
