import { useAuthContext } from "@tbl/aws-auth";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

export default function AccountIcon() {
  const { isAuthenticated } = useAuthContext();
  if (isAuthenticated) {
    return <AuthenticatedUserInfo />;
  } else {
    return <UnauthenticatedUserInfo />;
  }
}

function AuthenticatedUserInfo() {
  return (
    <Link className="subtle" to="/account">
      <Icon size="large" name="user" />
    </Link>
  );
}

function UnauthenticatedUserInfo() {
  return (
    <Link className="subtle" to="/account/register">
      <Icon size="large" name="user" />
    </Link>
  );
  /*
  return (
    <div className="account-info">
      <Link to="/account/register">Register</Link>
      <Link to="/account/login">Sign In</Link>
    </div>
  );
  */
}
