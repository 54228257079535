import type {PhotomapsWebsiteConfig} from ".";

const config:PhotomapsWebsiteConfig = {
  stage: "prod",
  photomapsApi: {
    url: "https://aghn4sgrgl.execute-api.us-east-1.amazonaws.com/prod"
  },
  aws: {
    region: "us-east-1",
    cognito: {
      userPoolId: "us-east-1_uNNqXyL4q",
      appClientId: "5rrfre7ntbe10r48olndhcn60t",
      identityPoolId: "us-east-1:cfbb50df-d7a8-4dfe-b1fb-da637cb66a9a",
    },
  },
  shopify: {
    domain: "photomaps-com.myshopify.com",
    storefrontAccessToken: "819d35b9368de95c36bcd89f7e3138a9",
  },
};
export default config;