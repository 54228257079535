import prod from "./PhotomapsDotComConfig.prod";
import type {PhotomapsWebsiteConfig} from ".";

const config:PhotomapsWebsiteConfig = {
  ...prod,
  photomapsApi: {
    url: "https://api-gamma.photomaps.com/gamma"
  },
  stage: "gamma",
};
export default config;