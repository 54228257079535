import { LoadingSpinner } from "@mapmaker/ui";
import Centered from "./Centered";

export default function LoadingPage() {
  return (
    <Centered>
      <LoadingSpinner />
    </Centered>
  );
}
