import { Link } from "react-router-dom";
import "./Footer.scss";

export default function Footer() {
  return (
    <footer id="pm-footer">
      <div className="content">
        <div className="links">
          <h3>Help</h3>
          <ul>
            <li>
              <Link className="subtle" to="/help">
                General Help
              </Link>
            </li>
            <li>
              <Link className="subtle" to="/contact">
                Contact Us
              </Link>
            </li>
            <li>
              <Link className="subtle" to="/refunds">
                Returns & Refunds
              </Link>
            </li>
          </ul>
        </div>
        <div className="links">
          <h3>Resources</h3>
          <ul>
            <li>
              <Link className="subtle" to="/about">
                Our Story
              </Link>
            </li>
            <li>
              <Link className="subtle" to="/privacy">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link className="subtle" to="/terms">
                Terms of Service
              </Link>
            </li>
          </ul>
        </div>
        <div className="stamp">
          <Link to="about" className="subtle">
            <img
              srcSet="/images/footer/made-in-mi-2x.png 2x, /images/footer/made-in-mi.png 1x"
              alt="State of Michigan with star near the city of Troy"
            />
            <p>Made in Michigan!</p>
          </Link>
        </div>
        <div className="copyright">
          © {new Date().getFullYear()} photomaps.com
        </div>
      </div>
    </footer>
  );
}
