import dev from "./PhotomapsDotComConfig.dev";
import type {PhotomapsWebsiteConfig} from ".";

const config:PhotomapsWebsiteConfig = {
  ...dev,
  stage: "local",
  photomapsApi: {
    url: "http://localhost:4033"
  }
};
export default config;