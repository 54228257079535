import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { useStorefrontCheckoutLight } from "@mapmaker/frontend";
import "./CartIcon.scss";

interface ICartIconProps {
  hideIfEmpty?: boolean;
}

export default function CartIcon({ hideIfEmpty = false }: ICartIconProps) {
  const { checkout, loading } = useStorefrontCheckoutLight();

  const numItems = useMemo(
    () =>
      checkout?.lineItems.edges.reduce(
        (qty, edge) => qty + edge.node.quantity,
        0
      ) || 0,
    [checkout]
  );

  if (hideIfEmpty && (loading || !checkout)) {
    return null;
  }
  return (
    <Link id="cart-icon" className="subtle" to="/cart">
      <Icon size="large" name="cart" />
      <span className="qty">{numItems || ""}</span>
    </Link>
  );
}
