import clsx from "clsx";
import React from "react";
import classes from "./Centered.module.scss";

type CenteredProps = {
  stretchHeight?: boolean;
  children: React.ReactNode;
};

export default function Centered({
  stretchHeight = true,
  children,
}: CenteredProps) {
  return (
    <div
      className={clsx({
        [classes.centered]: true,
        [classes.stretchHeight]: stretchHeight,
      })}
    >
      {children}
    </div>
  );
}
