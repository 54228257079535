import { ReactNode } from "react";
import useLockBodyScroll from "react-use/lib/useLockBodyScroll";
import useUpdateEffect from "react-use/lib/useUpdateEffect";
import useLatest from "react-use/lib/useLatest";
import { useLocation } from "react-router-dom";
import "./SlideoutMenu.scss";

type SlideoutMenuProps = {
  open: boolean;
  onClose: () => any;
  children: ReactNode;
};

export default function SlideoutMenu({
  open,
  onClose,
  children,
}: SlideoutMenuProps) {
  const location = useLocation();
  const onCloseLatest = useLatest<() => any>(onClose);
  useLockBodyScroll(open);
  useUpdateEffect(() => {
    onCloseLatest.current();
  }, [location]);

  return (
    <div id="slideout-menu" className={open ? "open" : "closed"}>
      <div className="backdrop" onClick={onClose} />
      <div className="close-button" onClick={onClose}>
        ✕
      </div>
      <div className="menu-content">{children}</div>
    </div>
  );
}
