import type {PhotomapsWebsiteConfig} from ".";

const config:PhotomapsWebsiteConfig = {
  stage: "dev",
  photomapsApi: {
    url: "https://api-dev.photomaps.com/dev"
  },
  aws: {
    region: "us-east-1",
    cognito: {
      userPoolId: "us-east-1_PzHC0YZ3E",
      appClientId: "1p49ni7cldiuifmrh4mejs6mdq",
      identityPoolId: "us-east-1:a3ebb451-9d5f-4f4c-bdb7-1b73d2776c65",
    },
  },
  shopify: {
    domain: "photomaps-com.myshopify.com",
    storefrontAccessToken: "819d35b9368de95c36bcd89f7e3138a9",
  },
};
export default config;